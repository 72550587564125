<template>
    <div class="delete-button" @click="deleteMemo">
        <img
            src="@assets/img/icons/trash-can.svg"
            class="delete"
            alt="delete"
        >
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "LikeButton",
    props: {
        memod: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        })
    },
    methods: {
        deleteMemo() {
            if (!this.isLoggedIn) {
                this.$modal.show("auth-modal", { allowClose: true, showSignIn: true });
            } else {
                this.$modal.show("delete-memo-modal", { memodId : this.memod.id });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.delete-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    span {
        display: block;
        position: absolute;
        left: 30px;
    }
}
</style>
